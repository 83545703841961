<template>
  <div>
    <back></back>
    <div class="detail">
      <h3 style="margin-bottom: 20px;">投标商品：{{ productName }}</h3>
      <div class="globle_table">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" max-height="540" empty-text="暂无供应商投标">
          <el-table-column align="center" v-if="status == 1" width="40">
            <template slot-scope="scope">
              <el-radio :label="scope.row.biddingProductId" v-model="multipleSelection">
                &nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center" v-if="status !== '1'">
            <template slot-scope="scope">
              {{ statusArr[Number(scope.row.status) - 1] }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierCode" label="供应商编码" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="supplierName" label="投标供应商" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="商品名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格" align="center"></el-table-column>
          <el-table-column prop="tenderProductPrice" label="投标金额(元)" align="center">
          </el-table-column>
          <!-- <el-table-column prop="createTime" label="开标时间" show-overflow-tooltip align="center">
          </el-table-column> -->
        </el-table>
      </div>
      <!-- <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
      </el-pagination> -->
    </div>
    <!-- <div style="text-align: center;">
      <el-button class="btn" type="primary" @click="determineTheWinningBid">确定中标</el-button>
    </div> -->
    <div style="text-align: center;" v-if="multipleSelection && status == 1">
      <el-button class="btn" type="primary" @click="determineTheWinningBid">确定中标</el-button>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {
      tendersId: null,
      loading: false,
      productId: "",
      productName: "",
      shopName: "", //搜索商品名称
      shopNumber: "", //搜索商品编号
      categoryId: "", //搜索分类id
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      form: {},
      status: null,
      options: [{}],
      tableData: [],
      multipleSelection: null,
      currentRow: null,
      statusArr: ["待评标", "已中标", "未中标", "废标"],
    };
  },
  created () {
    this.tendersId = this.$route.query.id;
    this.productId = this.$route.query.productId;
    this.productName = this.$route.query.productName;
    this.status = this.$route.query.status;
    this.getTendersInfoDetails();
    // this.getProductList()
    // this.onVisble();
  },
  methods: {
    // 下拉分类
    onVisble () {
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            auditStatus: 1,
          },
        })
        .then((res) => {
          this.options = res.data.result.list;
        });
    },
    // handleCurrentChange1(rows) {
    // console.log(rows);
    // if (this.multipleSelection.length == 1) {
    //   this.$message.error("最多选择一个");
    //   rows.forEach((row) => {
    //     if (row.a) {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     }
    //   });
    // }
    // this.multipleSelection = rows.map((t) => ({ tendersId: t.tendersId }));
    // console.log(this.multipleSelection)
    // },
    // 单选
    // 确定评标
    determineTheWinningBid () {
      this.$confirm(`是否确认评标?`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        const { tendersId, supplierId, productId } = this.tableData.find(
          (t) => t.biddingProductId == this.multipleSelection
        );
        this.$axios
          .post(this.$api.tenders_info_newOneEvaluation, {
            tendersId,
            supplierId,
            productId,
          })
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
            }
            // console.log(this.$route.query.tendersLabel);

            this.$router.go(-1);
          })
          .catch(() => {
            // this.$message.error(err.data.desc);
          });
      });
    },
    // 详情
    getTendersInfoDetails () {
      this.$axios
        .get(this.$api.tenders_info_new, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            tendersId: this.tendersId,
            productId: this.productId,
          },
        })
        .then((res) => {
          this.tableData = res.data.result.list;
          // this.tableData = [{ id: 1 }, { id: 2 }];
        })
        .catch(() => { });
    },
    // // 商品列表
    // getProductList() {
    //   this.$axios.get(this.$api.productList, {
    //     params: {
    //       auditStatus: 1,
    //       page: this.currentPage,
    //       pageSize: this.pageSize,
    //       productName: this.shopName,//商品名称
    //       serialId: this.shopNumber,//商品名称
    //       categoryId: this.categoryId//分类id
    //     }
    //   }).then((res) => {
    //     this.tableData = res.data.result.list
    //     this.totalItemsCount = res.data.result.totalCount
    //   }).catch(() => {
    //   });
    // },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e;
      this.getProductList();
    },
    // 换页
    handleCurrentChange () {
      this.page += 1;
      this.getProductList();
    },
    // 搜索
    onSearch () {
      this.getProductList();
    },
  },
};
</script>

<style lang="scss">
.detail {
  padding: 30px;
  border-radius: 6px;
  // border: 1px solid #e2e2e2;

  .top {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .text {
      p {
        margin-bottom: 20px;
        font-size: 16px;
        color: #707070;
      }

      .red {
        color: #eb2500;
      }

      .gray {
        color: #68706e;
      }
    }
  }

  .content {
    margin-top: 30px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-top: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .search {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 44px;
      margin-bottom: 20px;

      .el-input {
        width: 200px;
        margin-left: 20px;
      }

      .el-button {
        margin-left: 20px;
      }
    }
  }
}
</style>